import React, { useState } from "react";
import { IoMdCloseCircle } from "react-icons/io";
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import APIPath from "../../../../config";
import './PopupForm.css';

const PopupForm = ({ onClose,packageId, packageName }) => {
  const [peopleCount,setPeopleCount]=useState(0)
  const [childCount,setChildCount]=useState(0)
  const [infantCount,setInfantCount]=useState(0)

  const getCurrentDate = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = (now.getMonth() + 1).toString().padStart(2, '0');
    const day = now.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
}
  
  const [email,setEmail]=useState('');
  const handleEmailChange=(e)=>{
    setEmail(e.target.value);
  }

  const [name,setName]=useState('')
  const handleNameChange = (e) => {
    const name=e.target.value;
    const isAlphabetic = /^[a-zA-Z\s]*$/.test(name);
    if ((isAlphabetic || name === "") && name.length <= 15) {
      setName(name)
    }
  };
  const [mobile,setMobile]=useState();
  const handleMobileChange=(value)=>{
    setMobile(value)
  }
  const [message,setMessage]=useState('')
  const handleMessageChange=(e)=>{
    setMessage(e.target.value)
  }
  const [city,setCity]=useState('');
  const handleCityChange=(e)=>{
    setCity(e.target.value)
  }
  const [departureDate,setDepartureDate]=useState('');
  const handleDateChange=(e)=>{
    setDepartureDate(e.target.value)
  }
  const formData = {
    name: name,
    email: email,
    mobile: mobile,
    city: city,
    packageId:packageId,
    dateOfTravels: departureDate,
    numberOfAdults: peopleCount,
    numberOfChildren: childCount,
    message: message
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log(formData)
    fetch(`${APIPath}/api/v1/queries`, {
            headers: {
                'Content-Type': 'application/json',
              },    
        method: 'POST',
        body: JSON.stringify(formData)
      })
      .then(response => response.json())
        .then(data => {
          alert("Thank you for your query! Your query has been successfully sent to us. We will get back to you shortly.");
          onClose();
        })
        .catch(error => {
          alert(error);
          onClose();
        }); 
  };

  return (
    <div className="popup-form-container">
      <div className="popup-form">
       
        <div className="popupformtop">
            <h6 className="desktop-query">Your journey to forever is few steps away!
              just help us with below details
               </h6>
               <h4 className="mobile-query">Send Query</h4>
            <p className="popup-icon"><IoMdCloseCircle onClick={onClose} /></p>
        </div>
        <form onSubmit={handleSubmit} className="form">
        <div className="form-container"> 
             <h5 style={{fontWeight:"600",color:"#3F4266",marginTop:"3px",fontSize:"16px"}}> Basic Information </h5>
          <div className="form-group">
            <label className="package-label" style={{marginBottom:"3px"}}>Package Name:</label>
            <input type="text" name="name" id="packageName" value={packageName} required />
          </div>
          {/* ------------------------ Package Details ^----------- */}
          <div className="email-mobile">
              <div className="form-group">
                <label className="departure-city" style={{marginBottom:"3px",paddingRight:"8px"}}>City Name:</label>
                {/* <select name="city" id="city" 
                value={city}
                onChange={handleCityChange}>
                  <option value="Chandigarh">Select City</option>
                  <option value="New Delhi">New Delhi</option>
                  <option value="Kolkata">Kolkata</option>
                  <option value="Mumbai">Mumbai</option>
                  <option value="Chandigarh">Chandigarh</option>
                </select> */}
                <input type="text" onChange={handleCityChange} value={city} placeholder="Enter your city" maxLength={30}/>
              </div>

              <div className="form-group">
                <label className="departure-city" style={{marginBottom:"3px"}}>Select Date </label>
                <input type="date" name="dateOfTravels" id="dateoftravel" 
                min={getCurrentDate()}
                value={departureDate}
                onChange={handleDateChange}
                required />
              </div>
          </div>
          {/* -------------------------------------Select city and departure date ^ ----------------- */}
          <div className="people-count">
              <div className="adults-count">
                <p className="adults-count-label">Adults (Age 13 & above)</p>
                <p className="adult"> <button onClick={(e)=>{
                  e.preventDefault()
                  if(peopleCount > 0){
                    setPeopleCount(peopleCount - 1)
                   
                  }
                }}
               className="dec-btn" > - </button>
                 <b>{peopleCount}</b>
                <button onClick={(e)=>{
                  e.preventDefault()
                  setPeopleCount(peopleCount + 1)
                 
                }}
                className="inc-btn" > + </button> 
                </p>

              </div>
              <div className="adults-count">
                <p className="adults-count-label" >Children (Ages 3 to 12)</p>
                <p className="adult"> <button onClick={(e)=>{
                  e.preventDefault()
                  if(childCount > 0){
                    setChildCount(childCount - 1)
                    
                  }
                }}
                className="dec-btn" > - </button><b>{childCount}</b>
                <button onClick={(e)=>{
                  e.preventDefault()
                  setChildCount(childCount + 1)
                }}
                className="inc-btn"> + </button> </p>
              </div>
              <div className="adults-count">
                <p className="adults-count-label">Infants (Age 0 to 3 )</p>
                <p className="adult"> <button onClick={(e)=>{
                  e.preventDefault()
                  if(infantCount > 0){
                    setInfantCount(infantCount - 1)
                  }
                }}
                className="dec-btn"  > - </button><b>{infantCount}</b>
                <button onClick={(e)=>{
                  e.preventDefault()
                  setInfantCount(infantCount + 1)
                }}
                className="inc-btn"> + </button> </p>
              </div>

          </div>
         {/* ------------------------------------- People count ^ ------------------ */}
          <h5 style={{marginBottom:"0px",fontWeight:"600",color:"#3F4266",fontSize:"16px"}}>Contact Details</h5>
          <div className="email-mobile">
              <div className="form-group">
                <label className="departure-city">Your Full Name</label>
                <input type="text" 
                // name="name" 
                placeholder="Your Name..." 
                 value={name}
                onChange={handleNameChange} required />
              </div>

              <div className="form-group">
                <label className="departure-city">Your Email</label>
                <input type="email" name="email" placeholder="Enter your Email.."
                value={email}
                onChange={handleEmailChange} required />
              </div>

              <div className="form-group">
                 <label className="departure-city">Your Mobile no.</label>
                <PhoneInput 
                 required
                 country={'in'} 
                 value={mobile}
                 onChange={(value) => handleMobileChange(value)}
                 inputProps={{
                   name: 'mobile',
                   required: true,
              }}
            />
              </div>
          </div>
          {/* ----------------------------------Contact Detals ^------------------- */}
          
          <div className="form-group">
            <h5 style={{marginBottom:"5px",fontWeight:"600",color:"#3F4266",fontSize:"16px"}}> Any Specific Query?</h5>
            <textarea className="form-group-textarea" 
            name="message" placeholder="your Query..." 
            value={message}
            onChange={handleMessageChange} 
            required />
          </div>
          </div>  
          <button type="submit" className="submit-button">Send Enquiry <ArrowOutwardIcon /></button> 
       
        </form>
      </div>
    </div>
  );
};

export default PopupForm;
